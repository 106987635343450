// export default {
//     login: 'http://localhost:4000/#/login',
//     mmcDashboard: 'http://localhost:4000/#/independent-mover/dashboard',
//     api: "https://myrelo.us/stagingRelotechApi/public/Api/",
//     chatFile: 'https://myrelo.us/stagingRelotechApi/public/uploads/chat_files/',
//     edcBaseURL: "http://14.99.214.220/EDC/api/",
//     firebase: window.firebase.initializeApp({
//         apiKey: "AIzaSyCi0Jb8xw0fYfON2HrmTGBUWWxd0c4FnGA",
//         authDomain: "relotech-20063.firebaseapp.com",
//         databaseURL: "https://relotech-20063.firebaseio.com",
//         projectId: "relotech-20063",
//         storageBucket: "relotech-20063.appspot.com",
//         messagingSenderId: "34517605456",
//         appId: "1:34517605456:web:127a0b4506c76bc42ac555",
//         measurementId: "G-Y3BHRJDHE6"
//     }),
//     paypal: {
//         env: 'sandbox',  // production
//         currency: 'USD',
//         client: {
//             sandbox: 'AfimwhKCg4Gom37CBaITDbtEP3RT4wvDPo9X4mSZa7gCG8Zdlwww2Wea7EPhSEqtRCgNAQIueLc0-a7c',
//             production: 'YOUR-PRODUCTION-APP-ID',
//         }
//     }
// }

// export default {
//     login: 'https://relotech.us/dev/',
//     mmcDashboard:'https://relotech.us/dev/move_management_company/',
//     chatFile:'https://myrelo.us/relotechApi/public/uploads/chat_files/',
//     edcBaseURL:"http://14.99.214.220/EDC/api/",
//     firebase: window.firebase.initializeApp({
// apiKey: "AIzaSyDJo97zI6dJSSohIROgH8Sm0R-e7h5R3CM",
// authDomain: "myrelo-387e5.firebaseapp.com",
// databaseURL: "https://myrelo-387e5.firebaseio.com",
// projectId: "myrelo-387e5",
// storageBucket: "myrelo-387e5.appspot.com",
// messagingSenderId: "760939756162",
// appId: "1:760939756162:web:9ae16129b17ee1c2b5529b",
// measurementId: "G-QM4L3M639X"
// }),
//     paypal: {
//         env: 'production',  // sandbox
//         currency: 'USD',
//         client: {
//             sandbox: 'AfimwhKCg4Gom37CBaITDbtEP3RT4wvDPo9X4mSZa7gCG8Zdlwww2Wea7EPhSEqtRCgNAQIueLc0-a7c',
//             production: 'AU6GAzqguPcD0CCcciIz2GdSWHy0GlR7P4-Z5mRkWtVE6xYq5QmdV0HlKii27IKFmhNmkHHK-b6IHRVQ',
//         }
//     }
// }

// for live server

export default {
    login: 'https://imoversnet.com/#/login',
    mmcDashboard:'https://imoversnet.com/#/independent-mover/dashboard',
    api: "https://myrelo.us/relotechApi/public/Api/",
    chatFile:'https://myrelo.us/relotechApi/public/uploads/chat_files/',
    edcBaseURL:"http://14.99.214.220/EDC/api/",
    firebase: window.firebase.initializeApp({
    apiKey: "AIzaSyDJo97zI6dJSSohIROgH8Sm0R-e7h5R3CM",
    authDomain: "myrelo-387e5.firebaseapp.com",
    databaseURL: "https://myrelo-387e5.firebaseio.com",
    projectId: "myrelo-387e5",
    storageBucket: "myrelo-387e5.appspot.com",
    messagingSenderId: "760939756162",
    appId: "1:760939756162:web:9ae16129b17ee1c2b5529b",
    measurementId: "G-QM4L3M639X"
}),
    paypal: {
        env: 'production',  // sandbox
        currency: 'USD',
        client: {
            sandbox: 'AfimwhKCg4Gom37CBaITDbtEP3RT4wvDPo9X4mSZa7gCG8Zdlwww2Wea7EPhSEqtRCgNAQIueLc0-a7c',
            production: 'AU6GAzqguPcD0CCcciIz2GdSWHy0GlR7P4-Z5mRkWtVE6xYq5QmdV0HlKii27IKFmhNmkHHK-b6IHRVQ',
        }
    }
}

//for staging server

// export default {
//     login: 'https://staging.imoversnet.com/#/login',
//     mmcDashboard: 'https://staging.imoversnet.com/#/independent-mover/dashboard',
//     api: "https://myrelo.us/stagingRelotechApi/public/Api/",
//     chatFile: 'https://myrelo.us/stagingRelotechApi/public/uploads/chat_files/',
//     edcBaseURL: "http://14.99.214.220/EDC/api/",
//     firebase: window.firebase.initializeApp({
//         apiKey: "AIzaSyCi0Jb8xw0fYfON2HrmTGBUWWxd0c4FnGA",
//         authDomain: "relotech-20063.firebaseapp.com",
//         databaseURL: "https://relotech-20063.firebaseio.com",
//         projectId: "relotech-20063",
//         storageBucket: "relotech-20063.appspot.com",
//         messagingSenderId: "34517605456",
//         appId: "1:34517605456:web:127a0b4506c76bc42ac555",
//         measurementId: "G-Y3BHRJDHE6"
//     }),
//     paypal: {
//         env: 'production',  // sandbox
//         currency: 'USD',
//         client: {
//             sandbox: 'AfimwhKCg4Gom37CBaITDbtEP3RT4wvDPo9X4mSZa7gCG8Zdlwww2Wea7EPhSEqtRCgNAQIueLc0-a7c',
//             production: 'AU6GAzqguPcD0CCcciIz2GdSWHy0GlR7P4-Z5mRkWtVE6xYq5QmdV0HlKii27IKFmhNmkHHK-b6IHRVQ',
//         }
//     }
// }
